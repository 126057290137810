import type { SoviOnboardingStep } from '@/types/onboarding';

const getDescendantProp = <T>(obj: Record<string, any>, path: string) =>
  path.split('.').reduce((acc, part) => acc && acc[part], obj) as T;

const translations = {
  fi: {
    welcomeTour: {
      create: {
        title: 'Luo uusia sopimuksia',
        content: 'Voit luoda uuden sopimuksen tästä',
      },
      sort: {
        title: 'Järjestä',
        content: {
          createFolders: 'Luo kansioita',
          filterContracts: 'Suodata sopimuksia',
        },
      },
      search: {
        title: 'Etsi',
        content: 'Voit etsiä sopimuksia ja kansioita tästä',
      },
      settings: {
        title: 'Muut toiminnot',
        content: {
          activateBusinessAccount: 'Aktivoi yritystili',
          buyCredits: 'Osta allekirjoituskrediittejä',
          viewEvents: 'Näytä tapahtumat',
          manageSettings: 'Muokkaa asetuksia',
        },
      },
    },
    newContractTour: {
      documents: {
        title: 'Lisää asiakirjat',
        content: {
          title: 'Lisää allekirjoitettavat asiakirjat.',
          subtitle: 'Voit lisätä:',
          types: {
            pdf: 'PDF-asiakirjoja',
            new: 'Uusia asiakirjoja, jotka luot editorissa',
            template: 'Asiakirjoja asiakirjamallien pohjalta',
          },
          pleaseNote: 'Huomaa seuraavat ohjeet:',
          requirements: {
            passwordless: 'PDF-tiedostot eivät saa olla salasanasuojattuja',
            unsigned:
              'PDF-tiedostot eivät saa olla valmiiksi allekirjoitettuja',
          },
        },
      },
      participants: {
        title: 'Lisää allekirjoittajat',
        content: 'Lisää sopimuksen osapuolet',
        content2: 'Voit myös määrittää itsesi allekirjoittajaksi',
      },
      send: {
        title: 'Lähetä allekirjoitettavaksi',
        content:
          'Jokaiselle osapuolelle lähetetään sähköposti joka sisältää kirjautumislinkin',
      },
    },
  },
  en: {
    welcomeTour: {
      create: {
        title: 'Create new contracts',
        content: 'New contracts can be created here',
      },
      sort: {
        title: 'Arrange',
        content: {
          createFolders: 'Create folders',
          filterContracts: 'Filter contracts',
        },
      },
      search: {
        title: 'Search',
        content: 'You can search for contracts and folders here',
      },
      settings: {
        title: 'Other functions',
        content: {
          activateBusinessAccount: 'Activate business account',
          buyCredits: 'Buy signature credits',
          viewEvents: 'View events',
          manageSettings: 'Manage settings',
        },
      },
    },
    newContractTour: {
      documents: {
        title: 'Add documents',
        content: {
          title: 'Add the documents to be signed.',
          subtitle: 'You can add either:',
          types: {
            pdf: 'PDF documents',
            new: 'New documents you create on the editor',
            template: 'Documents based on contract templates',
          },
          pleaseNote: 'Please note:',
          requirements: {
            passwordless: 'PDF documents can not be password-protected',
            unsigned: 'PDF documents can not contain signatures',
          },
        },
      },
      participants: {
        title: 'Add signers',
        content: 'Add the parties of the contract',
        content2: 'You can also set yourself as a signatory',
      },
      send: {
        title: 'Send signature request',
        content: 'Participants will receive an email with sign-in link',
      },
    },
  },
};

const t = (locale: 'fi' | 'en', path: string) =>
  getDescendantProp<string>(translations[locale], path);

export const getOnboardingSteps = (
  locale: 'fi' | 'en',
  tourName: string,
): SoviOnboardingStep[] => {
  const tours = {
    welcomeTour: [
      {
        target: '[data-step="create"]',
        title: t(locale, 'welcomeTour.create.title'),
        body: [t(locale, 'welcomeTour.create.content')],
        position: {
          bottom: true,
          right: true,
        },
      },
      {
        target: '[data-step="sort"]',
        title: t(locale, 'welcomeTour.sort.title'),
        body: [
          [
            t(locale, 'welcomeTour.sort.content.createFolders'),
            t(locale, 'welcomeTour.sort.content.filterContracts'),
          ],
        ],
        position: {
          left: true,
          bottom: true,
        },
      },
      {
        target: '[data-step="search"]',
        title: t(locale, 'welcomeTour.search.title'),
        body: [t(locale, 'welcomeTour.search.content')],
        position: {
          left: true,
          bottom: true,
        },
      },
      {
        target: '[data-step="settings"]',
        title: t(locale, 'welcomeTour.settings.title'),
        body: [
          [
            t(locale, 'welcomeTour.settings.content.activateBusinessAccount'),
            t(locale, 'welcomeTour.settings.content.buyCredits'),
            t(locale, 'welcomeTour.settings.content.viewEvents'),
            t(locale, 'welcomeTour.settings.content.manageSettings'),
          ],
        ],
        position: {
          left: true,
          bottom: true,
        },
      },
    ],
    newContractTour: [
      {
        target: '[data-step="documents"]',
        title: t(locale, 'newContractTour.documents.title'),
        body: [
          t(locale, 'newContractTour.documents.content.title'),
          t(locale, 'newContractTour.documents.content.subtitle'),
          [
            t(locale, 'newContractTour.documents.content.types.pdf'),
            t(locale, 'newContractTour.documents.content.types.new'),
            t(locale, 'newContractTour.documents.content.types.template'),
          ],
          t(locale, 'newContractTour.documents.content.pleaseNote'),
          [
            t(
              locale,
              'newContractTour.documents.content.requirements.passwordless',
            ),
            t(
              locale,
              'newContractTour.documents.content.requirements.unsigned',
            ),
          ],
        ],
        position: {
          bottom: true,
          right: true,
        },
      },
      {
        target: '[data-step="participants"]',
        title: t(locale, 'newContractTour.participants.title'),
        body: [
          t(locale, 'newContractTour.participants.content'),
          t(locale, 'newContractTour.participants.content2'),
        ],
        position: {
          left: true,
          bottom: true,
        },
      },
      {
        target: '[data-step="send"]',
        title: t(locale, 'newContractTour.send.title'),
        body: [t(locale, 'newContractTour.send.content')],
        position: {
          left: true,
          bottom: true,
        },
      },
      // {
      //   target: '[data-step="search"]',
      //   title: t(locale, 'welcomeTour.search.title'),
      //   body: [t(locale, 'welcomeTour.search.content')],
      //   position: {
      //     left: true,
      //     bottom: true,
      //   },
      // },
      // {
      //   target: '[data-step="settings"]',
      //   title: t(locale, 'welcomeTour.settings.title'),
      //   body: [
      //     [
      //       t(locale, 'welcomeTour.settings.content.buyCredits'),
      //       t(locale, 'welcomeTour.settings.content.viewEvents'),
      //       t(locale, 'welcomeTour.settings.content.manageSettings'),
      //     ],
      //   ],
      //   position: {
      //     left: true,
      //     bottom: true,
      //   },
      // },
    ],
  };

  return tours[tourName as keyof typeof tours];
};
